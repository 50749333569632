import React, { useState, useEffect } from "react"
import Img from 'gatsby-image'
import { useIntl, Link } from "gatsby-plugin-intl"
import slugify from "../slugify/Slugify"

import FormStyles from './Form.module.scss'
import Modal from '../Modal/Modal'

import cloneDeep from 'lodash.clonedeep'
import LinkFormEL from './linkFormEL'

const ContactForm = ({ loading, success, submitHandler, submitText, formElements, linkCaption, linkTitle, link, error, cancelBtn, cancelHandler, imageChangeHandler, backHandler, formError, confirmModalText, pages, changeHandler}) => {
    const i18n = useIntl().formatMessage
    
    const linkVariables = {
        EXTERNAL_LINK: 'external_link',
        INTERNAL_LINK: 'internal_link',
        CONTACT_FORM: 'contact_form',
        LINK_TYPE: 'link_type'
    }


    const [submission, setSubmission] = useState(formElements.reduce((f, i) => ( i.type === 'link' ? {...f, link: {[linkVariables.LINK_TYPE]: i.value && i.value[linkVariables.LINK_TYPE] || linkVariables.CONTACT_FORM, [linkVariables.EXTERNAL_LINK]: i.value && i.value[linkVariables.EXTERNAL_LINK] || '', [linkVariables.INTERNAL_LINK]: i.value && i.value[linkVariables.INTERNAL_LINK] || ''}} : i.name === 'color_theme' ? {...f, primary_color: i.primaryValue, secondary_color: i.secondaryValue} : i.name === 'image' ? {...f, image: i.value, custom_image: ''} : i.name === 'select_image' ? {...f, select_image: i.value} : i.name === 'remember' ? {...f, remember: i.value} : { ...f, [i.name]: i.value ? i.value : '' }), {}))
    const [inputsValid, setInputsvalid] = useState(formElements.reduce((f, i) => ({ ...f, [i.name]: i.required && !submission[i.name] ? false : true}), {}))
    const [submissionValid, setSubmissionValid] = useState(false)
    const [edited, setEdited] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    useEffect(() => {
        if(checkValidity(inputsValid)) {
            setSubmissionValid(true)
        } else {
            setSubmissionValid(false)
        }        
    }, [inputsValid])

    useEffect(() => {
        const async = async () => {
            await formElements.reduce((f, i) => ( i.name === 'link' ? {...f, link: {[linkVariables.LINK_TYPE]: linkVariables.CONTACT_FORM, [linkVariables.EXTERNAL_LINK]: '', [linkVariables.INTERNAL_LINK]: ''}} : i.name === 'color_theme' ? {...f, primary_color: i.primaryValue, secondary_color: i.secondaryValue} : i.name === 'select_image' ? {...f, select_image: i.value} : i.type === 'checkbox' ? {...f, [i.name]: i.value} : { ...f, [i.name]: submission[i.name] || i.value }), {})
            await setInputsvalid(formElements.reduce((f, i) => ({ ...f, [i.name]: checkInputValidity(i.type, submission[i.name] || i.value, i.name, i.required || false)}), {}))
            if(checkValidity(submission)) {
                setSubmissionValid(true)
            }
        }
        console.log('FORMELS:', formElements)
        async()       
    }, [formElements])

 
    useEffect(() => {
        console.log('SUBMISSION:', submission)
        
    }, [submission])

    const checkValidity = (obj) => {
        for (var prop in obj) {
            if (obj[prop] === false) {
                return false  
            }
        }
        return true;
    }

    const checkInputValidity = (type, value, name, required) => {
        if(type === 'text' || type === 'textarea' || type === 'password' || type === 'url' || type === 'select' || type === 'select-one' || type === 'checkbox') {
            if(required && value) {
                if(name.includes('_repeat') && value !== submission[name.replace('_repeat', '')]) {
                    return false
                } else {
                    return true
                }
            } else if(!required) {
                return true
            } else {
                return false
            }
        } else if(type === "email") {
            const re = /^[a-zA-Z0-9.!#$%øæåöïóîäúü&'*+/=?^_`{|}~-]+@[A-Z0-9.øæåöïóîäúü-]+\.[A-Z]{2,}$/igm;
            if(value && value.match(re)) {
                return true
            } else if(value && !value.match(re)) {
                return false
            } else if(required) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const handleFocus = (event) => {
        if(!edited) {
            setEdited(true)
        }
        event.target.parentNode.classList.add(FormStyles.focus)
    }
    const handleBlur = (event) => {
        if((!event.target.value && event.target.parentNode.classList.contains(FormStyles.focus))) {
            event.target.parentNode.classList.remove(FormStyles.focus)
            event.target.parentNode.classList.remove(FormStyles.valid)
            if(event.target.required) {
                event.target.parentNode.classList.add(FormStyles.invalid)
            }
        } else if(event.target.value) {
            event.target.parentNode.classList.add(FormStyles.valid)
            
        }

    }
    const handleChange = (event) => {
        if(changeHandler) {
            changeHandler()
        }
        setSubmission({...submission, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value })
        
        if(event.target.type === 'text' || event.target.type === 'textarea' || event.target.type === 'password'  || event.target.type === 'url' || event.target.type === 'select'  || event.target.type === 'select-one') {
            handleChangeText(event)
        } else if(event.target.type === 'email') {
            handleChangeEmail(event)
        } else if(event.target.type === 'checkbox') {
            if(event.target.checked || !event.target.required) {
                setInputsvalid({...inputsValid, [event.target.name]: true}) 
                event.target.parentNode.classList.remove(FormStyles.invalid)
            } else {
                setInputsvalid({...inputsValid, [event.target.name]: false}) 
                event.target.parentNode.classList.add(FormStyles.invalid)
            }
        }
        
    }

    const handleChangeText = (event) => {
        if(event.target.value && !event.target.parentNode.classList.contains(FormStyles.focus)) {
            event.target.parentNode.classList.add(FormStyles.focus)
        } else if(event.target.value && event.target.parentNode.classList.contains(FormStyles.invalid)) {
            event.target.parentNode.classList.remove(FormStyles.invalid)
        }

        if(event.target.value) {
            if(event.target.name.includes('_repeat')) {
                if(event.target.value === submission[event.target.name.replace('_repeat', '')]) {
                    setInputsvalid({...inputsValid, [event.target.name]: true}) 
                } else {
                    event.target.parentNode.classList.remove(FormStyles.valid)
                    event.target.parentNode.classList.add(FormStyles.invalid)
                    setInputsvalid({...inputsValid, [event.target.name]: false}) 
                }
            } else if(event.target.type === 'select-one' && event.target.required) {
                event.target.parentNode.classList.remove(FormStyles.invalid)
                setInputsvalid({...inputsValid, [event.target.name]: true}) 
            } else {
                setInputsvalid({...inputsValid, [event.target.name]: true})
            }
        } else {
            if(event.target.required) {
                setInputsvalid({...inputsValid, [event.target.name]: false})
            }
            if(event.target.type === 'select-one') {
                event.target.parentNode.classList.remove(FormStyles.focus)
                event.target.parentNode.classList.remove(FormStyles.valid)
                if(event.target.required) {
                    event.target.parentNode.classList.add(FormStyles.invalid)
                }
            }
        }
    } 
    const handleChangeEmail = (event) => {
        const email = event.target.value
        const re = /^[a-zA-Z0-9.!#$%øæåöïóîäúü&'*+/=?^_`{|}~-]+@[A-Z0-9.øæåöïóîäúü-]+\.[A-Z]{2,}$/igm;
        if((!event.target.required && !email) || email.match(re)) {
            event.target.parentNode.classList.add(FormStyles.valid)
            event.target.parentNode.classList.remove(FormStyles.invalid)
            setInputsvalid({...inputsValid, [event.target.name]: true})
        } else {
            event.target.parentNode.classList.add(FormStyles.invalid)
            event.target.parentNode.classList.remove(FormStyles.valid)
            setInputsvalid({...inputsValid, [event.target.name]: false})
        }   
    }

    const handleColorPick = (i, primary, secondary, e) => {
        if(e.target.name === 'primary_color') {
            const async = async () => {
                await setSubmission({...submission, primary_color: e.target.value})
            }
            async()
        } else if(e.target.name === 'secondary_color') {
            const async = async () => {
                await setSubmission({...submission, secondary_color: e.target.value})
            }
            async()
        } else {
            const async = async () => {
            await    setInputsvalid({...inputsValid, primary_color: true, secondary_color: true})
            await    setSubmission({...submission, primary_color: primary, secondary_color: secondary})
            }
            async()
        }
    }

    const handleImageUpload = async (e) => {
        const url = URL.createObjectURL(e.target.files[0])

        const callback = async (f, mimetype) => {            

             const compressImage = (base64) => {
                const canvas = document.createElement('canvas')
                const img = document.createElement('img')
              
                return new Promise((resolve, reject) => {
                  img.onload = function () {
                    let width = img.width
                    let height = img.height
                    const maxHeight = 1600
                    const maxWidth = 1600
              
                    if (width > height) {
                      if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width))
                        width = maxWidth
                      }
                    } else {
                      if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height))
                        height = maxHeight
                      }
                    }
                    canvas.width = width
                    canvas.height = height
              
                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
              
                    resolve(canvas.toDataURL(`${mimetype}`, 0.7))
                  }
                  img.onerror = function (err) {
                    reject(err)
                  }
                  img.src = base64
                })
              }

              const compressed = await compressImage(f)

            setInputsvalid({...inputsValid, image: true})
            setSubmission({...submission, image_temp: url, image: compressed})
          }
        
        const read = (callback) => {
            var file = e.target.files[0];
            var reader = new FileReader();
          
            reader.onload = function() {
              callback(reader.result, file.type);
            }
          
            reader.readAsDataURL(file);
        }

        read(callback)
        
    }

    const formSubmitHandler = () => {
        setEdited(false)
        setShowConfirmModal(false)
    
        submitHandler(submission)
    }

    const confirmSubmitHander = () => {
        setShowConfirmModal(true)
    }

    const mouseOverDisabledSubmit = () => {

        const formGroups = document.querySelectorAll(`.${FormStyles.formgroup}`)

        for (let i = 0; i < formGroups.length; i++) {
            const el = formGroups[i]
            if(el) {
                const dataSet = el.dataset
                if(dataSet) {
                    if(dataSet.required && !dataSet.value) {
                        el.classList.add(FormStyles.invalid)
                    }
                }
            }  
        }
    }

    return (<>
            <div>
            <form onSubmit={e => { e.preventDefault(); }}>
                <input type="hidden" name="bot-field" />
                { formElements.map((f, i) => {
                    return (
                        
                            f.type === 'text' || f.type === 'email' || f.type === 'password'  || f.type === 'url' ?
                                <div key={i} className={[FormStyles.formgroup, f.value ? FormStyles.focus : null, f.postFix ? FormStyles.withPostFix : null].join(' ')} data-required={f.required} data-value={submission[f.name]}>
                                
                                    <label htmlFor={f.name} className={FormStyles.animLabel}>{ f.label }</label>
                                    {f.postFix ? <p className={FormStyles.postFix}>{ f.postFix }</p> : null}
                                    <input type={f.type} id={f.name} name={ f.name } required={ f.required } onFocus={ handleFocus } onBlur={ handleBlur } onChange={ handleChange } value={ submission[f.name] } autoComplete={ f.type } />
                                    <p className={[FormStyles.validation, "small text-red no-mb"].join(' ')}>{ f.errorText ? f.errorText : f.type === 'email' ? i18n({ id: "general.validations.email_error" }) : `${ i18n({ id: "general.validations.enter" })} ${f.label.toLowerCase()}` }</p>
                                    {f.help ? <p className="small no-mb text-italic">{ f.help }</p> : null}
                                </div>
                                
                            : f.type === 'textarea' ?
                                <div key={i} className={[FormStyles.formgroup, f.value ? FormStyles.focus : null].join(' ')}  data-required={f.required}  data-value={submission[f.name]}>
                                    
                                    <label htmlFor={f.name} className={FormStyles.animLabel}>{ f.label }</label>
                                    <textarea type={f.type} id={f.name} name={ f.name } required={ f.required } onFocus={ handleFocus } onBlur={ handleBlur } onChange={ handleChange } value={ submission[f.name] } autoComplete={ f.type }></textarea>
                                    <p className={[FormStyles.validation, "small text-red no-mb"].join(' ')}>
                                        { f.errorText ? f.errorText : f.type === 'email' ? i18n({ id: "general.validations.email_error" }) : `${ i18n({ id: "general.validations.enter" })} ${f.label.toLowerCase()}` }
                                    </p>
                                    {f.help ? <p className="small no-mb text-italic">{ f.help }</p> : null}
                                </div>
                            : f.type === 'checkbox' ?
                                <div key={i} className={[FormStyles.formgroup, FormStyles.checkboxGroup].join(' ')}  data-required={f.required}  data-value={submission[f.name]}>
                                    
                                    <input id={f.name} className={FormStyles.checkbox} type="checkbox" name={ f.name } required={ f.required } onFocus={ handleFocus } onBlur={ handleBlur } onChange={ handleChange } value={ submission[f.name] } checked={submission[f.name] ? true : false}/><label htmlFor={f.name}>{ f.label } <p className={[FormStyles.validation, "small text-red no-mb"].join(' ')}>{ f.errorText ? f.errorText : 'Påkrævet' }
                                    {f.help ? <p className="small no-mb text-italic">{ f.help }</p> : null}
                                    </p></label>
                                    
                                </div>
                            : f.type === 'select' ?
                                <div key={i} className={[FormStyles.formgroup, FormStyles.selectGroup, f.value ? FormStyles.focus : null].join(' ')}  data-required={f.required}  data-value={submission[f.name]}>       
                                    <label className={[FormStyles.animLabel, FormStyles.selectLabel].join(' ')} htmlFor={f.name}>{f.label}</label>
                                    <select name={f.name} id={f.name} onChange={ handleChange } value={ submission[f.name] } onBlur={handleBlur} required={f.required}>
                                        <option value=""></option>
                                        {f.options.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)}
                                    </select>
                                    <p className={[FormStyles.validation, "small text-red no-mb"].join(' ')}>{ f.errorText ? f.errorText : 'Foretag et valg' }</p>
                                    {f.help ? <p className="small no-mb text-italic">{ f.help }</p> : null}
                                </div>
                            : f.type === 'image' ?
                            <div key={i} className={FormStyles.image} >  
                                {submission[f.name] ?
                                <img src={submission[f.name]} id={f.name}/>
                                :
                                null
                                }
                                
                                <div className={[FormStyles.imageText, submission[f.name] ? FormStyles.imageTextWImage : null].join(' ')}>
                                    <label htmlFor={f.name} className="space-xs-up text-bold">{f.label}</label>
                                    <div className="flex start-xs top-xs middle-sm column-xs row-sm">
                                        <input type="file" name="custom_image" id="custom_image" accept=".jpg, .jpeg, .png" onChange={handleImageUpload} files={submission.image}/>
                                        <label htmlFor="custom_image">{ i18n({ id: "general.choose_image" }) }</label>
                                        <a className={FormStyles.btnImage} href="" onClick={(e) => imageChangeHandler(i, e, submission)}>{i18n({ id: "general.search_images" })} →</a>
                                     </div>
                                    
                                </div>
                                
                            </div>
                            : f.type === 'color_theme' ?
                            <div key={i} className={[FormStyles.colorTheme, 'space-xs-up'].join(' ')}>
                                <p className="text-bold no-mb">{f.label}</p>
                                <p>{f.help}</p>
                                <ul className={[FormStyles.colors, "list-inline"].join(' ')}>
                                    {f.options.map((o, i) => 
                                        <li key={i} className={submission.primary_color === o.primary && submission.secondary_color === o.secondary ? FormStyles.active : null}>
                                            <button className="btn btn-unstyled" onClick={(e) => handleColorPick(i, o.primary, o.secondary, e)}>
                                                <div className={FormStyles.colorWrapper}>
                                                    <div style={{backgroundColor: o.primary}}></div>
                                                    <div style={{backgroundColor: o.secondary}}></div>
                                                </div>
                                            </button>
                                        </li>
                                    )}
                                </ul>
                                <p>{f.customColor.help}</p>
                                <div className="flex between-xs column-xs row-md">
                                    <div className={["flex", FormStyles.customColorPick].join(' ')}>
                                        <div className={FormStyles.colorPicker} style={{backgroundColor: submission.primary_color || '#C7C7C7'}}>
                                            <input type="color" name="primary_color" value={submission.primary_color} onChange={(e) => handleColorPick(i, '', '', e )} style={{width: '5rem', height: '5rem', marginLeft: '-1rem', marginTop: '-1rem'}}/>
                                        </div>
                                        <div className={[FormStyles.formgroup, submission.primary_color ? FormStyles.focus : null].join(' ')}>
                                        
                                            <label htmlFor="primary_color" className={FormStyles.animLabel}>{ f.customColor.primary}</label>
                                            <input type="text" id="primary_color" name="primary_color" required={false} onFocus={ handleFocus } onBlur={ handleBlur } onChange={ (e) => handleColorPick(i, '', '', e )} value={ submission.primary_color } autoComplete={ f.type } />
                                            <p className={[FormStyles.validation, "small text-red no-mb"].join(' ')}>{ f.errorText ? f.errorText : f.type === 'email' ? i18n({ id: "general.validations.email_error" }) : `${ i18n({ id: "general.validations.enter" })} ${f.label.toLowerCase()}` }</p>
                                        </div>  
                                    </div>
                                    <div className="flex" className={["flex", FormStyles.customColorPick].join(' ')}>
                                        <div className={FormStyles.colorPicker} style={{ backgroundColor: submission.secondary_color || '#C7C7C7'}}>
                                            <input type="color" name="secondary_color" value={submission.secondary_color} onChange={(e) => handleColorPick(i, '', '', e )} style={{width: '5rem', height: '5rem', marginLeft: '-1rem', marginTop: '-1rem'}}/>
                                        </div>
                                        <div className={[FormStyles.formgroup, submission.secondary_color ? FormStyles.focus : null].join(' ')}>
                                        
                                            <label htmlFor="secondary_color" className={FormStyles.animLabel}>{ f.customColor.secondary}</label>
                                            <input type="text" id="secondary_color" name="secondary_color" required={false} onFocus={ handleFocus } onBlur={ handleBlur } onChange={ (e) => handleColorPick(i, '', '', e )} value={ submission.secondary_color } autoComplete={ f.type } />
                                            <p className={[FormStyles.validation, "small text-red no-mb"].join(' ')}>{ f.errorText ? f.errorText : f.type === 'email' ? i18n({ id: "general.validations.email_error" }) : `${ i18n({ id: "general.validations.enter" })} ${f.label.toLowerCase()}` }</p>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            : f.type === 'select_image' ?
                            <div key={i} className="space-xs-up">
                                <p className="text-bold no-mb">{f.label}</p>
                                <p>{f.help}</p>
                                <div className="row middle-xs">
                                        {f.options.map((option, i) =>
                                        <div key={i} className="col col-xs-12 col-md-6 space-xs-up">
                                            <div className={[FormStyles.choice, 'mb-small', submission[f.name] === option.value ? FormStyles.active : null].join(' ')} onClick={() => setSubmission({...submission, [f.name]: option.value})}>
                                                
                                                {option.image.fluid ?
                                                <Img fluid={option.image.fluid} />
                                                : option.image.fixed ?
                                                <Img fixed={option.image.fixed} />
                                                :
                                                <img src={option.image} />
                                                }
                                            </div>
                                            <p className="text-center">{option.label}</p>
                                        </div>
                                        )}
                                </div>
                            </div>
                            : f.type === 'link' ?
                            <LinkFormEL 
                                key={i}
                                FormStyles={FormStyles}
                                f={f}
                                submission={submission}
                                pages={pages}
                                handleChange={handleChange}
                                handleFocus={handleFocus} 
                                handleBlur={handleBlur}
                                linkVariables={linkVariables}
                                />                            
                            : null
                    )
                })}      
                
            </form>
            {error ? <p className="text-red">{error.Error}</p> : null}
            {formError && !edited ? 
                <p className="text-red mt">{i18n({ id: `general.errors.${slugify(formError)}.text`})}</p>
             : null           
            }
            <div className="flex flex-wrap column-xs row-md between-md middle-xs">
            
                { backHandler ? <button className={["btn btn-danger space-xs space-sm", FormStyles.btn].join(' ')} onClick={() => backHandler(submission)}>{ `⭠ ${i18n({ id: "general.back" })}` }</button> : null}

                {cancelBtn ? <button className={["btn btn-danger space-xs space-sm", FormStyles.btn].join(' ')} onClick={() => cancelHandler()}>{ i18n({ id: "general.cancel"}) }</button> : null}
                { linkCaption || link ? <p>{ linkCaption } { link && linkTitle ? <Link to={ link }>{ linkTitle }</Link> : null }</p> : null }
                
            <button 
                className={
                    [
                        "btn", 
                        (formError && !edited) || confirmModalText ? "btn-danger" : "btn-secondary", 
                        loading ? FormStyles.btnLoading : null, 
                        success ? FormStyles.btnSubmitSuccess : null,
                        FormStyles.btn,linkCaption || link ? 'space-xs space-sm' : null
                    ].join(' ')} 
                onClick={!confirmModalText ? () => formSubmitHandler() : () => confirmSubmitHander()} 
                onMouseEnter={(!submissionValid || (formError && !edited)) ? () => mouseOverDisabledSubmit() : null}
                disabled={!submissionValid || (formError && !edited)}>
                    {formError && !edited ? 
                        i18n({ id: `general.errors.${slugify(formError)}.title`}) || i18n({ id: `general.errors.error_occured.title`}) 
                    : <span>{ submitText }</span>}
            </button>
            
    
    <Modal
        show={showConfirmModal}
        closeHandler={() => setShowConfirmModal(false)}
        >
        <p>{confirmModalText}</p>
        <button 
                className={
                    [
                        "btn btn-danger", 
                        loading ? FormStyles.btnLoading : null, 
                        FormStyles.btn
                    ].join(' ')} 
                onClick={() => formSubmitHandler()} 
                >
                    <span>{ submitText }</span>
            </button>
    </Modal>
            </div>
                {/* <p className="text-red text-right text-bold mt"></p> */}
            </div>
        </>)
}

export default ContactForm
