import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useIntl } from "gatsby-plugin-intl"

const LinkFormEL = ({FormStyles, f, submission, pages, handleChange, handleFocus, handleBlur, linkVariables}) => {

    const i18n = useIntl().formatMessage

    const handleLinkChange = (e) => {
        console.log(e.target.name, ':', e.target.value)

        const name = e.target.name === 'link' ? 'link_type' : e.target.name

        const newValue = {
            ...submission.link,
            [name]: e.target.value
        }

        console.log('newValue', newValue)
        handleChange({target: {...e.target, name: 'link', value: newValue}})
    }



    return (
        <>
        <div className={[FormStyles.formgroup, FormStyles.valid, FormStyles.focus, FormStyles.selectGroup, submission && submission.link && (submission.link.link_type === linkVariables.EXTERNAL_LINK || submission.link.link_type === linkVariables.INTERNAL_LINK)  ? FormStyles.noMarginBottom : null].join(' ')}>       
            <label className={[FormStyles.animLabel, FormStyles.selectLabel].join(' ')} htmlFor={f.name}>{f.label}</label>
            <select name={f.name} id={f.name} onChange={ handleLinkChange } onBlur={handleBlur} onFocus={ handleFocus } value={submission && submission.link && submission.link[linkVariables.LINK_TYPE]}>
            {/* <select name={f.name} id={f.name} onChange={ handleLinkChange } value={ submission[f.name] } onBlur={handleBlur} required={f.required}> */}
                <option value={linkVariables.CONTACT_FORM}>{ i18n({ id: `form.link.options.${linkVariables.CONTACT_FORM}`}) }</option>
                <option value={linkVariables.INTERNAL_LINK}>{ i18n({ id: `form.link.options.${linkVariables.INTERNAL_LINK}`}) }</option>
                <option value={linkVariables.EXTERNAL_LINK}>{ i18n({ id: `form.link.options.${linkVariables.EXTERNAL_LINK}`}) }</option>
            </select>
            
            <p className={[FormStyles.validation, "small text-red no-mb"].join(' ')}>{ f.errorText ? f.errorText : 'Foretag et valg' }</p>
            {f.help ? <p className="small no-mb text-italic">{ f.help }</p> : null}
        </div>
        <div className={[FormStyles.formgroup, submission && submission.link && submission.link[linkVariables.EXTERNAL_LINK] ? [FormStyles.valid, FormStyles.focus].join(' '): null, submission.link && submission.link.link_type === linkVariables.EXTERNAL_LINK ? FormStyles.visible : FormStyles.invisible].join(' ')}>       
            <label htmlFor={linkVariables.EXTERNAL_LINK} className={FormStyles.animLabel}>{ i18n({ id: `form.link.options.${linkVariables.EXTERNAL_LINK}`}) }</label>
            <input type="url" id={linkVariables.EXTERNAL_LINK} name={linkVariables.EXTERNAL_LINK} value={submission && submission.link && submission.link[linkVariables.EXTERNAL_LINK]} onChange={ handleLinkChange } onFocus={ handleFocus } onBlur={ handleBlur } />
        </div>

         <div className={[FormStyles.formgroup, submission && submission.link && submission.link[linkVariables.INTERNAL_LINK] ? [FormStyles.valid, FormStyles.focus].join(' ') : null, FormStyles.selectGroup, submission.link && submission.link.link_type === linkVariables.INTERNAL_LINK ? FormStyles.visible : FormStyles.invisible].join(' ')}>  
            <label className={[FormStyles.animLabel, FormStyles.selectLabel].join(' ')} htmlFor={linkVariables.INTERNAL_LINK}>{i18n({ id: `form.link.${linkVariables.INTERNAL_LINK}.label`})}</label>
            <select name={linkVariables.INTERNAL_LINK} id={linkVariables.INTERNAL_LINK} value={submission && submission.link && submission.link[linkVariables.INTERNAL_LINK]} onChange={ handleLinkChange } onFocus={ handleFocus } onBlur={ handleBlur } >
                <option value=""></option>
                {pages.map((p, i) => (
                    <option key={i} value={p.id}>{ p.name }</option>
                ))}
            </select>
        </div>
        </>
    )
}

export default LinkFormEL